export default {
  127: [
    require('@/assets/plans/127.mp4'),
  ],
  138: [
    require('@/assets/plans/138.mp4'),
  ],
  175: [
    require('@/assets/plans/175.mp4'),
    require('@/assets/plans/175(2).mp4'),
  ],
  487: [
    require('@/assets/plans/487.mp4'),
  ],
  496: [
    require('@/assets/plans/496.mp4'),
    require('@/assets/plans/496(2).mp4'),
  ],
  561: [
    require('@/assets/plans/561.mp4'),
    require('@/assets/plans/561(2).mp4'),
  ],
  582: [
    require('@/assets/plans/582.mp4'),
    require('@/assets/plans/582(2).mp4'),
    require('@/assets/plans/582(3).mp4'),
  ],
}
