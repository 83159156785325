<template>
  <button
    class="favourite-icon"
    :class="{ active: status }"
    :aria-label="status ? 'Удалить из избранного' : 'Добавить в избранное'"
    @click.stop="favouritesStore.toggleFavourites(id)"
  >
    <Icon v-if="!status" icon="heart" />
    <Icon v-else icon="trash" />
  </button>
</template>

<script>
export default {
  inject: ["favouritesStore"],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    status() {
      return this.favouritesStore.state.favourites.indexOf(this.id) !== -1;
    },
  },
};
</script>

<style scoped>
.favourite-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 1px 0 0;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  transition: background-color 0.1s, border-color 0.1s;
  appearance: none;
}

.favourite-icon .icon-heart {
  transition: fill 0.1s, stroke 0.1s;
}

.favourite-icon.active {
  padding-top: 0;
  border-color: #205640;
  background: #205640;
}

body.-notouch .favourite-icon:hover {
  border-color: #a6bbb3;
}

body.-notouch .favourite-icon:active {
  border-color: #205640;
}

body.-notouch .favourite-icon.active:hover {
  border-color: #366753;
  background-color: #366753;
}

body.-notouch .favourite-icon.active:active {
  border-color: #1d4d3a;
  background-color: #1d4d3a;
}
</style>
