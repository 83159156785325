<template>
  <div v-if="orderedFlats.length" class="flats-mobile table-mobile">
    <template v-for="(flat, i) in orderedFlats" :key="i">
      <div
        v-if="flat.isBanner"
        class="flats-mobile__item table-mobile__item flats-table-avd__mobile"
      >
        <component
          :is="flat.component"
          :banner="flat.data"
          @click="(e, banner) => $emit('bannerClick', e, banner)"
        />
      </div>
      <div
        v-else
        class="flats-mobile__item table-mobile__item"
        @click="(e) => 
          routerPush(flat, e)
        "
      >
        <div class="row">
          <div class="col-auto">
            <div class="flats-mobile__image">
              <img
                v-if="flat.layout.plan_image"
                :class="{ 'plan-stub': flat.layout.plan_image.stub }"
                width="96"
                height="96"
                loading="lazy"
                :src="flat.layout.plan_image.image_thumb"
                alt="Планировка"
              />
            </div>
          </div>
          <div class="col flats-mobile__right">
            <div class="flats-mobile__content">
              <div class="flats-mobile__rooms">
                <router-link
                  :to="routeBuilder(flat)"
                  @click.stop
                >
                {{ isCommerce ? flat.title : $filters.flatRoomNormalize(flat.layout) }}
                </router-link>
                <span v-if="!isCommerce">
                  №{{ flat.number }}, корпус {{ flat.building }}
                </span>
              </div>

              <div class="flats-mobile__tags">
                <div
                  v-if="flat.status === 'booked'"
                  class="flats-mobile__tag btn btn-tag btn-orange"
                >
                  <Icon icon="lock2" />{{ $filters.bookedStatusNormalize(flat.layout.type) }}
                </div>
                <div
                  v-if="flatsVideo[flat.number]"
                  @click="clickViewHandler(flat)"
                  class="flats-mobile__tag btn btn-tag btn-lime flats-mobile__view-button"
                >
                  <Icon icon="image" />Вид из окна
                </div>
                <div
                  v-if="flat.properties._with_finished_layout"
                  class="flats-mobile__tag flats-mobile__tag--stroke btn btn-tag btn-lime"
                >
                  <Icon icon="roller" />c отделкой
                </div>
                <div
                  v-if="flat.properties._with_layout_furniture"
                  class="flats-mobile__tag btn btn-tag btn-lime"
                >
                  <Icon icon="roller" />отделка + мебель
                </div>
                <div
                  v-if="flat.properties._with_layout"
                  class="flats-mobile__tag btn btn-tag btn-lime"
                >
                  <Icon icon="roller" />доступна отделка
                </div>
                <div
                  v-if="flat.properties.with_design_project"
                  class="flats-mobile__tag btn btn-tag btn-lime"
                >
                  <Icon icon="gift" />дизайн-проект в подарок
                </div>
                <div
                  v-if="flat.properties.is_assignment"
                  class="flat__tag btn btn-tag btn-dark-grey "
                >
                  <Icon icon="key" />переуступка
                </div>
                <div
                  v-if="flat.discount"
                  class="flats-mobile__tag btn btn-tag btn-lime"
                >
                  <Icon icon="percent" />акция
                </div>
              </div>
              <div class="flats-mobile__favourite">
                <FavouritesIcon :id="flat.id" />
              </div>
              <!-- <div class="flats-mobile__label"><span>Акция</span></div> -->
              <div class="flats-mobile__info info info-small">
                <div v-if="flat.layout.area" class="info__row">
                  <div class="info__td">
                    <span>Площадь</span>
                  </div>
                  <div class="info__td">
                    <span v-html="$filters.squareFormat(flat.layout.area)" />
                  </div>
                </div>
                <div v-if="flat.floor" class="info__row">
                  <div class="info__td">
                    <span>Этаж</span>
                  </div>
                  <div class="info__td">
                    <span>{{ flat.floor.number }}</span>
                  </div>
                </div>
                <div class="info__row" v-if="!isCommerce">
                  <div class="info__td">
                    <span>Ипотека</span>
                  </div>
                  <div class="info__td">
                    <span
                      >{{
                        $filters.priceFormat(
                          $filters.mortgagePay({
                            cost: flat.discount_cost,
                            applyCoef: mortgage.apply_coefficient,
                            coef: mortgage.coefficient,
                            percent: mortgage.min_mortgage_percent,
                            years: mortgage.max_mortgage_bank_time,
                          })
                        )
                      }}&nbsp;/&nbsp;мес.</span
                    >
                  </div>
                </div>
                <div class="info__row" :class="{'info__cost--green': isCommerce && !flat.rent}">
                  <div class="info__td" v-if="!isCommerce || flat.rent">
                    <span>Стоимость</span>
                  </div>
                  <div class="info__td" v-else><strong>Узнать подробнее</strong></div>
                  <div v-if="!isCommerce" class="info__td info__cost">
                    <span v-if="flat.discount" class="info__new-cost"><strong>{{ $filters.priceFormat(flat.discount_cost) }}</strong></span>
                    <span :class="{ 'info__old-cost': flat.discount }"><strong>{{ $filters.priceFormat(flat.cost) }}</strong></span>
                  </div>
                  <div v-else class="info__td info__cost">
                    <span v-if="flat.rent"><strong>{{$filters.priceFormat(flat.rent)}} / мес</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
  <div v-if="false" class="flats-mobile-notfound">
    <div class="flats-mobile-notfound__title h6 d-none d-lg-block">
      Ничего не найдено
    </div>
    <p>Попробуйте изменить фильтр</p>
  </div>
</template>

<script>
import _orderBy from "lodash/orderBy";
import FavouritesIcon from "@/components/UI/FavouritesIcon.vue";
import flatsVideo from '@/content/flat.js'
export default {
  components: {
    FavouritesIcon,
  },
  inject: ["mainStore"],
  props: {
    flats: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
    },
    showAll: {
      type: Boolean,
      default: false
    },
    isCommerce: {
      type: Boolean,
      default: false
    },
    banners: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  emits: ["bannerClick"],
  data() {
    return {
      orderby: "cost",
      order: "asc",
    };
  },
  computed: {
    orderedFlats() {
      let flats = this.flats;

      if (!this.showAll) {
        flats = flats.filter(
          (item) => this.$buildings[item.building].isAvailable
        );
      }

      if (this.limit) {
        flats = flats.slice(0, this.limit);
      }

      if (this.orderby) {
        flats = _orderBy(flats, this.orderby, this.order);
      }
      let bannerId = 0;
      const out = [];
      flats.forEach((item, i) => {
        if (i % 5 === 0) {
          if (this.banners.length > bannerId) {
            out.push(this.banners[bannerId]);
            bannerId++;
          }
        }
        out.push(item);
      });
      while (this.banners.length > bannerId) {
        out.push(this.banners[bannerId]);
        bannerId++;
      }
      return out;
    },
    flatsVideo () {
      return flatsVideo
    },
    mortgage() {
      return this.mainStore.state.data?.mortgage ?? {};
    },
  },
  methods: {
    clickViewHandler (flat) {
      this.$vfm.show(
        {
          component: 'PopupLive',
          bind: {
            videos: flatsVideo[flat.number],
            title: 'Виды из окна'
          },
        }
      )
    },
    routeBuilder (flat) {
      if (this.isCommerce) {
        return{
          name: flat.properties.root,
          params: {
            id: flat.uuid,
          },
        }
      }
      return {
        name: 'PlanFlat',
        params: {
          korp: flat.building,
          sec: flat.section,
          floor: flat.floor?.number,
          fnumb: flat.number_on_floor,
          numb: flat.number,
          quantity: flat.layout?.room_count,
          id: flat.uuid,
        },
      }
    },
    routerPush (flat, e) {
      if (e.target.classList.contains('flats-mobile__view-button')) {
        return
      }
      this.$nextTick(() => {
        this.$router.push(this.routeBuilder(flat))
      })
    }
  }
};
</script>

<style scoped>
.flats-mobile {
  max-width: 510px;
  margin-right: auto;
  margin-left: auto;
}

.flats-mobile__content {
  position: relative;
}

.flats-mobile__favourite {
  position: absolute;
  top: 0;
  right: 0;
}

.flats-mobile__rooms {
  margin-bottom: 6px;
  padding-right: 60px;
  font-size: 18px;
}

.flats-mobile__rooms a {
  color: inherit;
  text-decoration: none;
}

.flats-mobile__rooms span {
  display: block;
  color: #646865;
  font-weight: normal;
  font-size: 14px;
}

.flats-mobile__tag {
  cursor: inherit;
  margin-right: 4px;
  margin-bottom: 4px;
}

.flats-mobile__tag--stroke {
  background-color: #fff!important;
  color: #205640!important;
  border-color: #205640!important;
}

.flats-mobile__view-button {
  position: relative;
}
.flats-mobile__view-button::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.flats-mobile__label {
  margin-bottom: 10px;
  line-height: 1;
}

.flats-mobile__tags {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2px;
  padding-bottom: 12px;
}

.flats-mobile__label span {
  display: inline-block;
  padding: 7px 5px;
  border-radius: 2px;
  background: #509c3b;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.flats-mobile__info .info__td:first-child {
  color: #8c8f8c;
}

.info__new-cost {
  color: #509C3B;
}
.info__old-cost {
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  text-align: right;
  text-decoration-line: line-through;
  color: #C5C7C6;
  position: absolute;
  right: 0;
  top: 100%;
}

.info__cost {
  position: relative;
}

@media (max-width: 767px) {
  .flats-mobile__info {
    font-size: 15px;
  }
}

@media (max-width: 575px) {
  .flats-mobile__right {
    padding-left: 0;
  }

  .flats-mobile__image img {
    width: 80px;
    height: 80px;
  }

  .flats-mobile__label span {
    padding-top: 5px;
    padding-bottom: 6px;
    font-size: 10px;
  }

  .flats-mobile__info {
    font-size: 14px;
  }

  .flats-mobile__info .info__row {
    margin-bottom: 2px;
  }
}
@media (max-width: 374px) {
  .flats-mobile__info {
    font-size: 12px;
  }

  .flats-mobile__info .info__row {
    background-position: center bottom 5px;
  }
}

.flats-mobile-notfound {
  margin-top: 20px;
}

.flats-mobile-notfound__title {
  margin-bottom: 15px;
}

.flats-table-avd__mobile {
  padding-bottom: 0;
  margin-top: -25px;
  border-bottom: 0;
}

.flats-table-avd__mobile + .flats-table-avd__mobile {
  margin-top: 0;
}

.info__cost--green {
  background-image: none!important;
}
.info__cost--green .info__td {
  color: #509C3B!important;
}
</style>
